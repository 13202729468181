import SurveyHeader from './SurveyHeader';
import { FunctionComponent } from 'react';
import {
  CloseButton,
  PrimaryButton,
  SecondaryOutlinedButton,
} from '@hedwig/buttons';
import { useI18n } from '../language/i18n';
import { IconSet, IconSize } from '@hedwig/icons';

const SurveyGreeting: FunctionComponent<SurveyGreetingProps> = ({
  closeSurvey,
  onNext,
  headerText,
  text,
}) => {
  const [translation] = useI18n();
  return (
    <>
      <CloseButton
        onClick={closeSurvey}
        iconSet={IconSet.LIGHT}
        iconSize={IconSize.MEDIUM}
        extraClassName="popup-survey__close-button"
      />
      <SurveyHeader text={headerText} />
      <p
        className="hw-block--pt-small-2 hw-block--mb-medium-2"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <div className="hw-text-right hw-block--mb-small-3">
        <PrimaryButton onClick={onNext} extraClassName="hw-button--full">
          {translation.common.popup_survey.greeting.start_button}
        </PrimaryButton>
        <SecondaryOutlinedButton
          onClick={closeSurvey}
          extraClassName="hw-button--full hw-block--mt-small-2"
        >
          {translation.common.popup_survey.greeting.close_button}
        </SecondaryOutlinedButton>
      </div>
    </>
  );
};

interface SurveyGreetingProps {
  closeSurvey: () => void;
  onNext: () => void;
  headerText: string;
  text: string;
}

export default SurveyGreeting;

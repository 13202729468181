import { useState, useEffect, useCallback, FunctionComponent } from 'react';
import { useTracking } from '../analytics/Analytics';
import './popup-survey.less';
import {
  setAskedToAnswerSurvey,
  previouslyAskedToAnswerSurvey,
  setUserTypeSender,
  ShortUserType,
} from './satisfactionSurvey';
import SatisFactionSurveyContent from './SatisfactionSurveyContent';
import { OrganizationSector } from '../organizations/organizationSector';

interface PopupSurveyContainerProps {
  userType: ShortUserType;
  trigger?: string;
  product?: string;
  sector?: OrganizationSector;
  context: string;
  showAfterMs?: number;
  percentageOfUsersToAsk?: number;
  id?: string | number;
}

const PopupSurveyContainer: FunctionComponent<PopupSurveyContainerProps> = ({
  showAfterMs = 5000,
  product = 'virksomhet',
  sector,
  id,
  userType,
  trigger = `${showAfterMs / 1000} sek etter sidelast`,
  context,
  percentageOfUsersToAsk = 1,
}) => {
  const { consent, registerAnalyticsEvent } = useTracking();
  const [showSurvey, setShowSurvey] = useState(false);

  const open = useCallback(() => {
    setShowSurvey(true);
    registerAnalyticsEvent({
      category: `${product} - Popupsurvey`,
      action: 'Vist',
      label: `${context} - ${trigger}`,
    });
  }, [product, context, trigger, registerAnalyticsEvent]);

  const close = () => {
    setShowSurvey(false);
    registerAnalyticsEvent({
      category: `${product} - Popupsurvey`,
      action: 'Lukket',
      label: `${context} - ${trigger}`,
    });
  };

  useEffect(() => {
    if (consent.analytics) {
      const timer = setTimeout(() => {
        if (
          !previouslyAskedToAnswerSurvey() &&
          Math.random() <= percentageOfUsersToAsk
        ) {
          open();
          setAskedToAnswerSurvey();
        }
      }, showAfterMs);
      return () => clearTimeout(timer);
    }
  }, [consent.analytics, open, showAfterMs, percentageOfUsersToAsk]);

  useEffect(() => {
    if (consent.analytics && userType === ShortUserType.SENDER) {
      setUserTypeSender();
    }
  }, [consent.analytics, userType]);

  const satisfactionSurvey = (
    <SatisFactionSurveyContent
      userType={userType}
      trigger={trigger}
      product={product}
      sector={sector}
      context={context}
      close={close}
      id={id}
    />
  );

  return consent.analytics ? (
    <div className="popup-survey__listener">
      {showSurvey && (
        <div
          className="popup-survey__modal-overlay"
          role="presentation"
          onClick={close}
        >
          <div
            role="presentation"
            className="popup-survey__modal"
            aria-label="Popup undersøkelse"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {satisfactionSurvey}
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default PopupSurveyContainer;

import { FunctionComponent } from 'react';

import { useI18n } from '../language/i18n';
import { CommentsAltIcon, IconColor } from '@hedwig/icons';

const OpenFeedbackFormButton: FunctionComponent<
  OpenFeedbackFormButtonProps
> = ({ formVisibility, toggleFeedbackForm }) => {
  const [translation] = useI18n();
  return (
    <button
      onClick={toggleFeedbackForm}
      aria-expanded={formVisibility}
      aria-controls="feedback-form"
      aria-label={
        translation.common.feedback.mainbutton.aria_label[
          formVisibility ? 'expanded' : 'collapsed'
        ]
      }
      className={`hw-button feedback-button${formVisibility ? ' hidden' : ''}`}
      type="button"
    >
      <>
        <CommentsAltIcon
          color={IconColor.WHITE}
          extraClassName="icon-with-text"
        />
        {translation.common.feedback.form.button}
      </>
    </button>
  );
};

interface OpenFeedbackFormButtonProps {
  toggleFeedbackForm: () => void;
  formVisibility: boolean;
}

export default OpenFeedbackFormButton;

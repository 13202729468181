import { FunctionComponent, useState } from 'react';

import OpenFeedbackFormButton from './OpenFeedbackFormButton';
import FeedbackForm from './FeedbackForm';
import ThankYouBox from './ThankYouBox';
import {
  enkelSigneringSendFeedbackToGoogleSheets,
  postFeedbackToGoogleDocs,
} from './api';
import config from '../config';
import { useI18n } from '../language/i18n';
import { AppName } from '../analytics/appNames';
import { Validation, ValidationType } from '../validation/validation';

const validateComment = (comment: string) => {
  return comment.trim() !== '';
};

const FeedbackMascot: FunctionComponent<{
  appName?: AppName;
}> = ({ appName }) => {
  const [translation, , format] = useI18n();
  const [state, setState] = useState<{
    formVisibility: boolean;
    chosenRating?: number;
    comment: string;
    feedbackSubmitted: boolean;
    commentValidation: Validation;
  }>({
    formVisibility: false,
    feedbackSubmitted: false,
    comment: '',
    chosenRating: undefined,
    commentValidation: { result: ValidationType.NOT_VALIDATED },
  });

  const submitFeedback = () => {
    const formData = {
      Tid: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      URL: window.location.href,
      Applikasjon: appName,
      Rating: state.chosenRating,
      Tilbakemelding: state.comment,
      Environment: config.isDevModeEnabled ? 'TEST' : 'PROD',
    };
    void postFeedbackToGoogleDocs(formData);
  };

  const enkelSigneringSubmitFeedback = () => {
    void enkelSigneringSendFeedbackToGoogleSheets(state.comment);
  };

  const onSubmitFeedback = () => {
    if (validateComment(state.comment)) {
      if (appName === AppName.PRIVATE) {
        enkelSigneringSubmitFeedback();
      } else {
        submitFeedback();
      }

      setState({
        ...state,
        feedbackSubmitted: true,
        comment: '',
        chosenRating: undefined,
        commentValidation: { result: ValidationType.NOT_VALIDATED },
      });
    } else {
      setState({
        ...state,
        commentValidation: {
          result: ValidationType.INVALID_FIELD,
          errorMessage: translation.common.feedback.comment.error_message,
        },
      });
    }
  };

  const showCorrectForm = (feedbackSubmitted: boolean) => {
    if (feedbackSubmitted) {
      return (
        <ThankYouBox
          closeForm={() =>
            setState({
              ...state,
              formVisibility: false,
              commentValidation: { result: ValidationType.NOT_VALIDATED },
            })
          }
        />
      );
    } else {
      return (
        <FeedbackForm
          onSubmitFeedback={onSubmitFeedback}
          updateFormData={(updatedFields) => {
            setState({
              ...state,
              ...updatedFields,
            });
          }}
          formData={state}
          closeFeedbackForm={() =>
            setState({
              ...state,
              formVisibility: false,
              commentValidation: { result: ValidationType.NOT_VALIDATED },
            })
          }
        />
      );
    }
  };

  return (
    <aside
      className="feedback-container ui-medmax"
      aria-label={translation.common.feedback['aria-label']}
    >
      {state.formVisibility && showCorrectForm(state.feedbackSubmitted)}
      <OpenFeedbackFormButton
        formVisibility={state.formVisibility}
        toggleFeedbackForm={() =>
          setState({
            ...state,
            formVisibility: !state.formVisibility,
            feedbackSubmitted: false,
            commentValidation: { result: ValidationType.NOT_VALIDATED },
          })
        }
      />
    </aside>
  );
};

export default FeedbackMascot;

import { FunctionComponent } from 'react';

const RadioButton: FunctionComponent<RadioButtonProps> = ({
  label,
  value,
  setSatisfactionScore,
  satisfactionScore,
  nextQuestion,
}) => {
  return (
    <label className="hw-radio-button hw-radio-button--bounding hw-radio-button--full hw-block--mb-smaller">
      <input
        type="radio"
        name={label}
        value={value}
        checked={satisfactionScore === value}
        onChange={(e) => {
          setSatisfactionScore(parseInt(e.target.value));
          nextQuestion();
        }}
        onClick={() => {
          if (satisfactionScore === value) {
            nextQuestion();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === ' ' || e.key === 'Spacebar') {
            nextQuestion();
          }
        }}
      />
      <span className="hw-radio-button__label hw-text-smaller">{label}</span>
      <i className="hw-radio-button__indicator" />
    </label>
  );
};

interface RadioButtonProps {
  label: string;
  value: number;
  setSatisfactionScore: (score: number) => void;
  nextQuestion: () => void;
  satisfactionScore: number;
}

export default RadioButton;

import { FunctionComponent, useState } from 'react';
import SurveyGreeting from './SurveyGreeting';
import SatisfactionSurveyForm from './SurveyForm';
import SurveyGratitude from './SurveyGratitude';
import { useTracking } from '../analytics/Analytics';
import { ShortUserType } from './satisfactionSurvey';
import { OrganizationSector } from '../organizations/organizationSector';
import { useI18n } from '../language/i18n';

interface SatisFactionSurveyContentProps {
  userType: ShortUserType;
  trigger?: string;
  product?: string;
  sector?: OrganizationSector;
  context: string;
  showAfterMs?: number;
  close: () => void;
  id?: string | number;
}

const SatisFactionSurveyContent: FunctionComponent<
  SatisFactionSurveyContentProps
> = ({
  showAfterMs = 5000,
  userType,
  trigger = `${showAfterMs / 1000} sek etter sidelast`,
  product = 'virksomhet',
  sector,
  context,
  close,
  id,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();
  const [currentStep, setCurrentStep] = useState(0);

  const nextSurveyStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const satisfactionSurvey = [
    <SurveyGreeting
      key="SurveyGreeting"
      closeSurvey={close}
      onNext={() => {
        registerAnalyticsEvent({
          category: `${product} - Popupsurvey`,
          action: 'Startet',
          label: `${context} - ${trigger}`,
        });
        nextSurveyStep();
      }}
      headerText={translation.common.popup_survey.greeting.header}
      text={translation.common.popup_survey.greeting.text}
    />,
    <SatisfactionSurveyForm
      key="SurveyForm"
      onNext={nextSurveyStep}
      userType={userType}
      trigger={trigger}
      product={product}
      sector={sector}
      context={context}
      closeSurvey={close}
      id={id}
    />,
    <SurveyGratitude
      closeSurvey={close}
      key="SurveyGratitude"
      headerText={translation.common.popup_survey.thankyou.header}
      text={translation.common.popup_survey.thankyou.text}
      buttonText={translation.common.popup_survey.thankyou.close_button}
    />,
  ][currentStep];

  return satisfactionSurvey;
};

export default SatisFactionSurveyContent;

import { FunctionComponent } from 'react';
import { withParams } from '../language/i18n';

const SurveyHeader: FunctionComponent<SurveyHeaderProps> = ({
  text,
  params,
}) => {
  return params ? (
    <h3
      className="hw-h3 hw-text-title hw-color-primary-darker hw-block--mb-small-2"
      dangerouslySetInnerHTML={{
        __html: withParams(text, { questionNumber: params?.questionNumber }),
      }}
    ></h3>
  ) : (
    <h3 className="hw-h3 hw-text-title hw-color-primary-darker hw-block--mb-small-2">
      {text}
    </h3>
  );
};

interface SurveyHeaderProps {
  text: string;
  params?: {
    questionNumber: string;
  };
}

export default SurveyHeader;

import { FunctionComponent, PropsWithChildren } from 'react';

interface SurveyQuestionProps {
  text: string;
}

const SurveyQuestion: FunctionComponent<
  SurveyQuestionProps & PropsWithChildren
> = ({ text, children }) => {
  return (
    <section className="popup-survey__question hw-block--pt-small hw-block--pb-small">
      <h4 className="hw-block--mb-small">{text}</h4>
      {children}
    </section>
  );
};

export default SurveyQuestion;

import { FunctionComponent } from 'react';
import SurveyHeader from './SurveyHeader';
import { CloseButton, SecondaryOutlinedButton } from '@hedwig/buttons';
import { IconSet, IconSize } from '@hedwig/icons';

const SurveyGratitude: FunctionComponent<SurveyGratitudeProps> = ({
  closeSurvey,
  headerText,
  text,
  buttonText,
}) => {
  return (
    <>
      <CloseButton
        onClick={closeSurvey}
        iconSet={IconSet.LIGHT}
        iconSize={IconSize.MEDIUM}
        extraClassName="popup-survey__close-button"
      />
      <SurveyHeader text={headerText} />
      <p className="hw-block--pt-small-2 hw-block--mb-medium-2">{text}</p>
      <div className="hw-text-right hw-block--mb-small-3">
        <SecondaryOutlinedButton
          extraClassName="hw-button--full"
          onClick={closeSurvey}
        >
          {buttonText}
        </SecondaryOutlinedButton>
      </div>
    </>
  );
};

interface SurveyGratitudeProps {
  closeSurvey: () => void;
  headerText: string;
  text: string;
  buttonText: string;
}

export default SurveyGratitude;

import { IS_NOT_LOCALHOST } from '../environment';
import { findCookie, getValueFromCookie } from '../analytics/Analytics';
import { addDuration, TimeUnit } from '../time/time';

const SATISFACTION_SURVEY_COOKIE_NAME = 'posten_signering_satisfaction_survey';

export enum ShortUserType {
  SENDER = 'a',
  SIGNER = 'u',
}

export const shortUserTypeToGoogleSheetsFormat = (userType: ShortUserType) => {
  return userType === ShortUserType.SENDER ? 'avsender' : 'undertegner';
};

interface CookieObject {
  askedToAnswer: boolean;
  userType: ShortUserType;
}

const updateSurveyCookie = (cookieObject: CookieObject) => {
  document.cookie = `${SATISFACTION_SURVEY_COOKIE_NAME}=${JSON.stringify(
    cookieObject
  )}; Domain=${
    IS_NOT_LOCALHOST ? '.signering.posten.no' : 'localhost'
  }; Path=/; expires=${addDuration(
    TimeUnit.DAY,
    90,
    new Date()
  ).toUTCString()};`;
};

const surveyCookieExists = (): boolean => {
  return findCookie(SATISFACTION_SURVEY_COOKIE_NAME) !== undefined;
};

const getSurveyCookieObject = (): CookieObject => {
  const surveyCookie = findCookie(SATISFACTION_SURVEY_COOKIE_NAME);
  return getValueFromCookie<CookieObject>(surveyCookie ? surveyCookie : '');
};

const previouslyAskedToAnswerSurvey = (): boolean => {
  return surveyCookieExists() && getSurveyCookieObject().askedToAnswer;
};

const setUserTypeSender = (): void => {
  if (!previouslyAskedToAnswerSurvey()) {
    if (!surveyCookieExists()) {
      updateSurveyCookie({
        askedToAnswer: false,
        userType: ShortUserType.SENDER,
      });
    } else {
      updateSurveyCookie({
        askedToAnswer: getSurveyCookieObject().askedToAnswer,
        userType: ShortUserType.SENDER,
      });
    }
  }
};

const setAskedToAnswerSurvey = (): void => {
  if (!surveyCookieExists()) {
    updateSurveyCookie({
      askedToAnswer: true,
      userType: ShortUserType.SIGNER,
    });
  } else {
    updateSurveyCookie({
      askedToAnswer: true,
      userType: getSurveyCookieObject().userType,
    });
  }
};

const getUserTypeFromSurveyCookie = (): ShortUserType | undefined => {
  if (surveyCookieExists()) {
    return getSurveyCookieObject().userType;
  }
};

export {
  surveyCookieExists,
  setAskedToAnswerSurvey,
  previouslyAskedToAnswerSurvey,
  setUserTypeSender,
  getSurveyCookieObject,
  getUserTypeFromSurveyCookie,
};

import { FunctionComponent } from 'react';
import { CloseButton, SecondaryButton } from '@hedwig/buttons';
import { IconSet, IconSize } from '@hedwig/icons';
import { useI18n } from '../language/i18n';

const ThankYouBox: FunctionComponent<{
  closeForm: () => void;
}> = ({ closeForm }) => {
  const [translation] = useI18n();
  return (
    <div
      className="feedback-form__thank-you-message"
      role="dialog"
      aria-live="polite"
      aria-modal={true}
    >
      <CloseButton
        ariaLabel={translation.common.primarybutton.close}
        onClick={closeForm}
        iconSet={IconSet.LIGHT}
        iconSize={IconSize.MEDIUM}
      />
      <h3
        id="feedback-thankyou-heading"
        tabIndex={-1}
        className="hw-h3 hw-text-title hw-block--mb hw-text-center feedback-heading"
      >
        {translation.common.feedback.thankyou.header}
      </h3>
      <p className="thank-you-text">
        {translation.common.feedback.thankyou.text}
      </p>
      <SecondaryButton onClick={closeForm}>
        {translation.common.primarybutton.close}
      </SecondaryButton>
    </div>
  );
};

export default ThankYouBox;

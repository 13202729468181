import { useState, FunctionComponent } from 'react';

import { sendPopupSurveyResponseToGoogleSheets } from './googleSheets';
import RadioButton from './RadioButton';
import SurveyQuestion from './SurveyQuestion';
import SurveyHeader from './SurveyHeader';
import { useTracking } from '../analytics/Analytics';
import {
  ShortUserType,
  shortUserTypeToGoogleSheetsFormat,
} from './satisfactionSurvey';
import {
  CloseButton,
  PrimaryButton,
  PrimaryOutlinedButton,
  widths,
} from '@hedwig/buttons';
import { useI18n } from '../language/i18n';
import { OrganizationSector } from '../organizations/organizationSector';
import { IconSet, IconSize } from '@hedwig/icons';

import './popup-survey.less';

const SurveyForm: FunctionComponent<SurveyFormProps> = ({
  onNext,
  userType,
  trigger,
  product,
  sector,
  context,
  closeSurvey,
  id,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [satisfactionScore, setSatisfactionScore] = useState(0);
  const [comment, setComment] = useState('');

  const sendSurveyResponse = () => {
    void sendPopupSurveyResponseToGoogleSheets({
      comment,
      satisfactionScore,
      trigger,
      userType: shortUserTypeToGoogleSheetsFormat(userType),
      product,
      sector,
      context,
      id,
    });
    registerAnalyticsEvent({
      category: `${product} - Popupsurvey`,
      action: 'Fullført',
      label: `${context} - ${trigger}`,
    });
    onNext();
  };

  const nextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const previousQuestion = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  const radioButtons = [
    { value: 7, label: translation.common.popup_survey.question_one.rating_7 },
    { value: 6, label: translation.common.popup_survey.question_one.rating_6 },
    { value: 5, label: translation.common.popup_survey.question_one.rating_5 },
    { value: 4, label: translation.common.popup_survey.question_one.rating_4 },
    { value: 3, label: translation.common.popup_survey.question_one.rating_3 },
    { value: 2, label: translation.common.popup_survey.question_one.rating_2 },
    { value: 1, label: translation.common.popup_survey.question_one.rating_1 },
  ];

  const getFollowUpQuestion = () => {
    if (satisfactionScore > 5) {
      return translation.common.popup_survey.question_two.satisfied.text;
    } else if (satisfactionScore === 5 || satisfactionScore === 4) {
      return translation.common.popup_survey.question_two.neutral.text;
    } else {
      return translation.common.popup_survey.question_two.dissatisfied.text;
    }
  };

  const currentSurveyQuestion = () => {
    switch (currentQuestion) {
      case 1: {
        return (
          <SurveyQuestion
            text={translation.common.popup_survey.question_one.text}
          >
            <div className="popup-survey__radio-buttons-container">
              {radioButtons.map((radioButton) => (
                <RadioButton
                  key={radioButton.value}
                  value={radioButton.value}
                  label={radioButton.label}
                  setSatisfactionScore={setSatisfactionScore}
                  satisfactionScore={satisfactionScore}
                  nextQuestion={nextQuestion}
                />
              ))}
            </div>
          </SurveyQuestion>
        );
      }
      case 2: {
        return (
          <SurveyQuestion text={getFollowUpQuestion()}>
            <label className="hw-label" htmlFor="feedback-textarea">
              {translation.common.popup_survey.question_two.comment.label}
            </label>
            <textarea
              className="hw-textarea popup-survey__comment-input"
              name="feedback-textarea"
              id="feedback-textarea"
              placeholder={
                translation.common.popup_survey.question_two.comment.placeholder
              }
              rows={5}
              required={false}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </SurveyQuestion>
        );
      }
    }
  };

  return (
    <form
      className="popup-survey__form"
      onSubmit={(e) => {
        e.preventDefault();
        sendSurveyResponse();
      }}
    >
      <CloseButton
        onClick={closeSurvey}
        iconSet={IconSet.LIGHT}
        iconSize={IconSize.MEDIUM}
        extraClassName="popup-survey__close-button"
      />
      <SurveyHeader
        text={translation.common.popup_survey.question.header}
        params={{ questionNumber: currentQuestion.toString() }}
      />
      {currentSurveyQuestion()}
      {currentQuestion === 2 && (
        <div>
          <PrimaryButton type="submit" width={widths.responsive}>
            {translation.common.popup_survey.submit_button}
          </PrimaryButton>
          <PrimaryOutlinedButton
            width={widths.responsive}
            onClick={previousQuestion}
          >
            {translation.common.popup_survey.previous_question_button}
          </PrimaryOutlinedButton>
        </div>
      )}
    </form>
  );
};

interface SurveyFormProps {
  onNext: () => void;
  userType: ShortUserType;
  trigger: string;
  product: string;
  context: string;
  sector?: OrganizationSector;
  closeSurvey: () => void;
  id?: string | number;
}

export default SurveyForm;
